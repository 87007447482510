import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "HomeView",
      component: () => {
        return import("../views/HomeView.vue");
      },
    },
    {
      path: "/immergewinner",
      alias: "/immergewinner.html",
      name: "gewinn",
      component: () => {
        return import("../views/GewinnVue.vue");
      },
    },
    {
      path: "/faq.html",
      name: "FAQView",
      component: () => {
        return import("../views/FAQView.vue");
      },
    },
    {
      path: "/404.html",
      name: "PageNotFoundView",
      component: () => {
        return import("../views/NotFound.vue");
      },
    },
    {
      path: "/:catchAll(.*)",
      redirect: "/404.html",
    },
  ],
});

export default router;
