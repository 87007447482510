(function () {
  if (
    "undefined" !== typeof window &&
    void 0 !== window.Reflect &&
    void 0 !== window.customElements
  ) {
    const a = HTMLElement;
    window.HTMLElement = function () {
      return Reflect.construct(a, [], this.constructor);
    };
    HTMLElement.prototype = a.prototype;
    HTMLElement.prototype.constructor = HTMLElement;
    Object.setPrototypeOf(HTMLElement, a);
  }
})();
export * from "../dist/esm/polyfills/index.js";
export * from "../dist/esm/loader.js";
