import { createApp } from "vue";

import App from "./App.vue";
import routerIndex from "./router/router_index";
import routerGewinn from "./router/router_gewinn";

import {
  applyPolyfills,
  defineCustomElements,
} from "./dbui/elements-enterprise/loader";

import "./dbui/dbui/css/db-ui-core.vars.css";
import "./dbui/style.css";
import "video.js/dist/video-js.css";

// console.log(window.location.href);

applyPolyfills();
defineCustomElements();
const app = createApp(App);

const hrefURL = window.location.href;

if (hrefURL.includes("gewinn")) {
  app.use(routerGewinn);
} else {
  app.use(routerIndex);
}

app.mount("#app");
