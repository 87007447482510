<script setup lang="ts">
const footerData = [
  {
    label: "Impressum",
    href: "https://www.bahn.de/app_impressum_pk_de_DEU.shtml",
  },
  { label: "FAQ", href: "/faq.html" },
  {
    label: "Beförderungsbedingungen",
    href: "https://www.bahn.de/app_agb_pk_de_DEU.shtml",
  },
  {
    label: "Nutzungsbedingungen",
    href: "https://www.bahn.de/app_nutzung_pk_de_DEU.shtml",
  },
  {
    label: "Vertrag kündigen",
    href: "https://www.bahn.de/app_vertraege_pk_de_DEU.shtml",
  },
  {
    label: "Datenschutz",
    href: "https://www.bahn.de/app_datenschutz_pk_de_DEU.shtml",
  },
  { label: "Konzern", href: "https://www.bahn.de/app_konzern_pk_de_DEU.shtml" },
];
</script>
<template>
  <footer id="db-footer-custom">
    <ul>
      <li target="_blank" v-for="(footerLi, i) in footerData" :key="i">
        <a :href="footerLi.href" title="">{{ footerLi.label }}</a>
      </li>
      <li>© DB Vertrieb GmbH</li>
    </ul>
  </footer>
</template>
<style>
footer#db-footer-custom {
  background-color: var(--db-color-cool-gray-200);
  font-family: var(--db-asset-font-db-screensans-regular-name);
  padding: 0px 15px 0 15px;
  display: block;
}

footer#db-footer-custom ul {
  /*border-top: 1px solid #AFB4BB;*/
  text-align: center;
  padding: 24px 0px;
  margin: 0;
}

footer#db-footer-custom li,
footer#db-footer-custom li a {
  font-family: var(--db-asset-font-db-screensans-regular-fontfamily);
  font-size: 14px;
  color: #282d37;
}

footer#db-footer-custom li {
  list-style: none;
  display: inline-block;
  margin: 0 8px 8px;
  white-space: nowrap;
}

footer#db-footer-custom li a {
  text-decoration: none;
}

footer#db-footer-custom li a:hover {
  text-decoration: underline;
}

footer#db-footer-custom li a:focus-visible {
  outline-color: #101010;
  outline-style: auto;
}
</style>
