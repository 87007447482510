<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();
</script>
<template>
  <db-header>
    <db-brand
      @click="router.push('/')"
      src="/publicAssets/images/ic_brand_32.svg"
      alt="Brand"
      href="/"
    ></db-brand>
  </db-header>
</template>
<style>
db-header {
  margin: 0 auto;
  width: 980px;
}
@media screen and (max-width: 980px) {
  main.rea-main db-header {
    position: relative;
    overflow: visible;
  }
}

.langDE {
  position: absolute;
  top: 2rem;
  right: 20px;
}

.langEN {
  position: absolute;
  top: 2rem;
  right: 120px;
}

.langDE:hover,
.langEN:hover {
  cursor: pointer;
  color: #ec0016;
}

header .cmp-brand img.elm-image.is-logo {
  width: 32px;
  height: 32px;
}

db-header header.rea-header {
  box-shadow: 0 6px 8px -4px rgb(0 0 0 / 30%);
  margin-bottom: 0;
  height: 56px;
}

.rea-header .cmp-brand {
  padding-top: 15px;
}

db-header header.rea-header {
  border-bottom: none;
  height: 56px;
  min-height: 56px;
}
</style>
